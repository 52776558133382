import type { PropType } from 'vue'
import { type Cell, ColumnDef, FlexRender } from '@tanstack/vue-table'
import { useTableStore } from './composables'
import { withModifiers } from 'vue'

export default defineComponent({
  name: 'DataTableCell',
  props: {
    cell: {
      type: Object as PropType<Cell<any, any>>,
      required: true,
    },
  },
  setup(props) {
    const { getCommonStyles } = useTableStore()!
    const isSelect = props.cell.column.id === 'select'

    return () => (
      <td
        style={{
          ...getCommonStyles(props.cell.column),
          width: `calc(var(--col-${props.cell.column.id}-size) * 1px)`,
        }}
        onClick={
          isSelect
            ? withModifiers(() => {
                // Prevent propagation of the click event
              }, ['stop'])
            : undefined
        }
      >
        <FlexRender
          render={props.cell.column.columnDef.cell}
          props={props.cell.getContext()}
        />
      </td>
    )
  },
})
